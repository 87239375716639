import ErrorInfo from '@core_modules/home/pages/default/components/ErrorInfo';
import { getHomeCategorySlider } from '@modules/home/service/graphql';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Skeleton from '@material-ui/lab/Skeleton';
import Link from 'next/link';
import { useTranslation } from '@i18n';
import { GRAY_700 } from '@root/src/theme/colors';
import Thumbor from '@common_imageThumbor';
import CarouselV2 from '../../../../commons/CarouselV2';

const useStyles = makeStyles({
    scrollContainer: {
        gap: '24px',
        '@media (min-width: 767px)': {
            gap: '61px',
        },
    },
    cell: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 50,
        gap: '4px',
        '@media (min-width: 768px)': {
            width: 120,
        },
        '& img': {
            objectFit: 'cover',
            '@media (max-width: 767px)': {
                width: 64,
                height: 64,
            },
        },
    },
    title: {
        fontSize: 8,
        fontWeight: 500,
        textAlign: 'center',
        color: GRAY_700,
        '@media (min-width: 768px)': {
            fontSize: 16,
        },
    },
});

// const imgUrl = (url) => (
//     `http://thumbor.sirclocdn.com/unsafe/120x120/filters:format(webp)/${url}`);

export default function CategoryGrid() {
    const { loading, data, error } = getHomeCategorySlider();
    const { t } = useTranslation(['home']);
    const styles = useStyles();

    if (loading) return <Skeleton variant="rect" width="100%" height={170} />;

    if (error) return <ErrorInfo variant="error" text={t('home:errorFetchData')} />;

    if (!data.categories?.home_category) return <ErrorInfo variant="warning" text={t('home:nullData')} />;

    const categories = data.categories.home_category;

    return (
        <CarouselV2 scrollContainerProps={{ className: styles.scrollContainer }}>
            {categories.map(({
                id, name, url_key, image_icon,
            }) => (
                <Link prefetch={false} href={url_key} key={id}>
                    <a className={styles.cell}>
                        {/* <img
                            src={imgUrl(image_icon)}
                            alt={name}
                            onError={(e) => {
                                e.target.src = '/assets/img/placeholder.png';
                            }}
                            width="120"
                            height="120"
                        /> */}
                        <Thumbor
                            className={styles.imgProduct}
                            src={image_icon || '/assets/img/placeholder.png'}
                            alt={name}
                            width={120}
                            height={120}
                        />
                        <span className={styles.title}>{name}</span>
                    </a>
                </Link>
            ))}
        </CarouselV2>
    );
}
